import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import "../scss/main.scss"
import Modal from "./Modal"

const Layout = ({ children, header = true, location }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)
  return (
    <>
      <Header location={location} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <footer>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
