import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import logo from "../images/logo-v.svg"
import hamburger from "../images/hamburger.svg"
import close from "../images/close.svg"

import { Location } from "@reach/router"

const menu = [
  {
    link: "/zespol",
    text: "Zespół",
    page: true
  },
  {
    link: "/#oferta",
    text: "Oferta"
  },
  {
    link: "/cennik",
    text: "Cennik",
    page: true
  },
  {
    link: "/szkolenia",
    text: "Szkolenia",
    page: true
  },
  {
    link: "/blog",
    text: "Blog",
    page: true
  },
  {
    link: "/#kontakt",
    text: "Kontakt"
  }
]

const Header = ({ siteTitle, location }) => {
  const [menuActive, setMenuActive] = useState(false)
  const isHomePage = location.pathname === "/"

  const toggleMenu = () => setMenuActive(!menuActive)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", (e) => {
        const header = document.querySelector(".site-header")
        const headerPortal = document.querySelector(".gatsby-plugin-transition-link-portal .site-header")

        if (window.scrollY >= 100) {
          header && header.classList.add("slim")
          headerPortal && headerPortal.classList.add("slim")
        } else {
          header && header.classList.remove("slim")
          headerPortal && headerPortal.classList.remove("slim")
        }
      })
    }
  }, [])

  const handleAnchorClick = (e, item) => {
    if (isHomePage) {
      e.preventDefault()
      setMenuActive(false)
      const elementId = item.link.replace("/#", "")

      const element = document.querySelector(`#${elementId}`)
      element && element.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <header className={`site-header ${menuActive && "active"}`}>
      <div className="header-content">
        <div className="header-logo">
          <h1>
            <span className="u-visually-hidden">{siteTitle}</span>
            {isHomePage ? (
              <a href="/#start" onClick={(e) => {
                handleAnchorClick(e, { link: "/#start" })
              }}>
                <img src={logo} alt={siteTitle} />
              </a>
            ) : (
              <AniLink fade to="/" onClick={() => setMenuActive(false)}>
                <img src={logo} alt={siteTitle} />
              </AniLink>
            )}
          </h1>
          <div onClick={toggleMenu} className="menu-button--mobile menu-close">
            <img src={close} alt="Close" />
          </div>
          <div onClick={toggleMenu} className="menu-button--mobile menu-hamburger">
            <img src={hamburger} alt="Menu" />
          </div>
        </div>
        <div className="mobile-wrapper">
          <ul className="site-nav">
            {menu.map(menuItem => (
              <li key={menuItem.link}>
                {menuItem.page ? (
                  <AniLink fade to={menuItem.link} className="site-nav__item" onClick={() => setMenuActive(false)}>{menuItem.text}</AniLink>
                ) : (
                  <>
                    {isHomePage ? (
                      <a href={menuItem.link} onClick={(e) => {
                        handleAnchorClick(e, menuItem)
                      }}
                         className="site-nav__item">{menuItem.text}</a>
                    ) : (
                      <AniLink fade
                               to={menuItem.link}
                               className="site-nav__item"
                               onClick={() => setMenuActive(false)}
                      >
                        {menuItem.text}
                      </AniLink>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
