import React from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import Layout from "./layout"
import { navigate } from "@reach/router"
import Header from "./Header"
import CloseIcon from "../images/close.svg"

const Modal = ({ children, isAbout = false, location }) => {
  if (location && location.state && location.state.modal) {
    return (
      <>
        <div className={`modal-close-wrapper ${isAbout && "modal-close-wrapper--about"}`}>
          <a onClick={() => navigate(-1)}>
            <img src={CloseIcon}  alt="Close"/>
          </a>
        </div>
        {children}
        <div className="modal-back-wrapper">
          <a className="modal-back-button" onClick={() => navigate(-1)}><span>&lt;</span> Wróć </a>
        </div>
      </>
    )
  }
  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) => {
        return (
          <div>
            {modal ? (
              <>
                <div className={`modal-close-wrapper ${isAbout && "modal-close-wrapper--about"}`}>
                  <a onClick={() => navigate(-1)}>
                    <img src={CloseIcon}  alt="Close"/>
                  </a>
                </div>
                {children}
                <div className="modal-back-wrapper">
                  <a className="modal-back-button" onClick={() => navigate(-1)}><span>&lt;</span> Wróć </a>
                </div>
              </>
            ) : (
              <>
                <div className={`modal-close-wrapper ${isAbout && "modal-close-wrapper--about"}`}>
                  <a onClick={() => navigate(-1)}>
                    <img src={CloseIcon}  alt="Close"/>
                  </a>
                </div>
                {children}
                <div className="modal-back-wrapper">
                  <a className="modal-back-button" onClick={() => navigate(-1)}><span>&lt;</span> Wróć </a>
                </div>
              </>
            )}
          </div>
        )
      }}
    </ModalRoutingContext.Consumer>
  )
}

Modal.propTypes = {}

Modal.defaultProps = {}

export default Modal
